.wrap[data-v-75bcb4da] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  /* max-width: 1100px; */
  align-self: center;
}
.actions-buttons-container[data-v-75bcb4da] {
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
}
.buttons-container[data-v-75bcb4da] {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
}
.button-text[data-v-75bcb4da] {
  font-weight: 600;
}
.button-secondary[data-v-75bcb4da] {
  font-weight: 600;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  color: #da0f2e;
}
.subtitle[data-v-75bcb4da] {
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #333333;
  text-align: left;
  line-height: normal;
  font-size: 20px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.filter-row[data-v-75bcb4da] {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}
.filter-container[data-v-75bcb4da] {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}
.column[data-v-75bcb4da] {
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 0;
  margin-right: 30px;
}
.gray-divider[data-v-75bcb4da] {
  border: 1px solid #797979;
  width: 100%;
  margin-bottom: 20px;
  opacity: 50%;
}
.dropdown-spinner-container[data-v-75bcb4da] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.results-modal-header-title[data-v-75bcb4da] {
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  width: 50%;
}
.results-modal-id[data-v-75bcb4da] {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  width: 50%;
}
.results-modal-result[data-v-75bcb4da] {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  width: 50%;
  word-wrap: break-word;
}
.results-modal-row[data-v-75bcb4da] {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
