.wrap[data-v-c14d73d5] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  /* max-width: 1100px; */
  align-self: center;
}
.actions-buttons-container[data-v-c14d73d5] {
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
}
.buttons-container[data-v-c14d73d5] {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 30px;
}
.button-text[data-v-c14d73d5] {
  font-weight: 600;
}
.button-secondary[data-v-c14d73d5] {
  font-weight: 600;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  color: #da0f2e;
}
.check-item[data-v-c14d73d5] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.checkbox[data-v-c14d73d5] {
  height: 0;
  width: 0;
  margin-right: 10px;
}
