.wrap[data-v-7c5e9d63] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 10px 60px;
    min-height: calc(100vh - 170px);
}
.header[data-v-7c5e9d63] {
    font-size: 24px;
    margin-bottom: 10px;
}
.button[data-v-7c5e9d63] {
    min-width: 390px;
    height: 42px;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid #797979;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #797979;
    text-align: center;
    line-height: 40px;
    margin: 12px 0;
    margin-right: 40px;
    transition: 0.1s;
    -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
    user-select: none; /* Standard */
}
.button[data-v-7c5e9d63]:hover {
    background-color: #da0f2edd;
    cursor: pointer;
    color: white;
}
.button[data-v-7c5e9d63]:active {
      color: white;
      box-shadow: inset 0px 0px 5px #666;
      outline: none;
}
.section[data-v-7c5e9d63] {
    margin: 1em 0;
    max-width: 1300px;
}
.row[data-v-7c5e9d63] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}