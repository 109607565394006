.wrap[data-v-14388039] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1100px;
  align-self: center;
}
.header[data-v-14388039] {
  font-size: 24px;
  font-weight: 700;
  color: #da0f2e;
  margin: 24px 0 0 30px;
  align-self: left;
}
.row[data-v-14388039] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sub-header[data-v-14388039] {
  font-size: 24px;
  font-weight: 700;
  color: #53565a;
  margin: 24px 0 0 30px;
}
