.wrap[data-v-f80170b2] {
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding: 30px 60px;
  min-height: calc(100vh - 170px);
}
.table-titles[data-v-f80170b2] {
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
}
.title[data-v-f80170b2] {
  font-weight: 700;
  color: #333333;
  font-size: 20px;
  margin: 25px 0;
}
.thick-label[data-v-f80170b2] {
  font-weight: 700;
  color: #333333;
  font-size: 20px;
}
.bar[data-v-f80170b2] {
  height: 40px;
  padding: 2px;
  background-color: #d9001b;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.label[data-v-f80170b2] {
  color: #333333;
  font-size: 20px;
  margin: 0;
}
.value[data-v-f80170b2] {
  color: #33333388;
  font-size: 16px;
  display: flex;
  padding: 5px 0;
}
.content[data-v-f80170b2] {
  border: 2px solid #da102d;
  padding: 20px;
}
.inner[data-v-f80170b2] {
  width: 1140px;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.inner-inner[data-v-f80170b2] {
  justify-content: space-between;
  display: flex;
  margin: 0;
}
.row-margin[data-v-f80170b2] {
  margin-top: 15px;
}
.dropdown[data-v-f80170b2] {
  border: 1px solid #333;
  border-radius: 5px;
}
.rectangle[data-v-f80170b2] {
  width: 100%;
  height: 304px;
  background-color: #ddd;
}
.headers[data-v-f80170b2] {
  margin-top: 20px;
  font-weight: 700;
}
.plus[data-v-f80170b2] {
  font-weight: 800;
  margin: 15px;
}
.button[data-v-f80170b2] {
  height: 40px;
  margin: 0 15px;
}
