
.overlay-box[data-v-066ba35c] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 100;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display:flex;
  justify-content: center;
  align-items: center;
}
.spinner-wrap[data-v-066ba35c] {
  position: relative;
  z-index: 110;
  width: 300px;
  height: 150px;
  border-radius: 5px;
  background-color: white;
  box-shadow: #aaa 0px 0px 5px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.spinner[data-v-066ba35c] {
  margin-right: 10px;
}
.spinner-text[data-v-066ba35c] {
  font-size: 20px;
  font-weight: 600;
  color: #da0f2e;
  margin-left: 10px;
  display:flex;
  justify-content: center;
}
