.scroll-list[data-v-ad396745] {
  width: 100%;
}
.gray-text[data-v-ad396745] {
  color: gray;
}
.summary-input[data-v-ad396745] {
  width: 100%;
  height: 50px;
  resize: none;
}
