.wrap[data-v-a3c9fa53] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  max-width: 95%;
}
.inner-wrap[data-v-a3c9fa53] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.title[data-v-a3c9fa53] {
  font-size: 22px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #53565a;
  text-align: left;
  margin: 32px 0;
}
