.menu-item[data-v-158e1fc2] {
  background-color: white;
  width: 314px;
  height: 80px;
  padding: 2px 2px 2px 80px;
  border-radius: 5px;
  border: 1px solid #797979;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #797979;
  font-size: 20px;
  flex-direction: row;
  display: flex;
  position: relative;
  align-items: center;
  margin: 20px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: 0.1s;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.menu-item[data-v-158e1fc2]:hover {
  color: #444;
  border: 1px solid #333;
  background-color: #fafafa;
  margin-top: 19px;
  text-shadow: rgba(255, 255, 255, 0.9) 0px 1px 1px;
}
.menu-item[data-v-158e1fc2]:active {
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}
.icon[data-v-158e1fc2] {
  position: absolute;
  left: 10px;
}
.description-text[data-v-158e1fc2] {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}
.title-text[data-v-158e1fc2] {
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
}
.text-wrapper[data-v-158e1fc2] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
