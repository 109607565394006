a[data-v-49628ad1] {
  color: #da0f2e;
  text-decoration: none;
  background-color: transparent;
}
a[data-v-49628ad1]:hover {
  cursor: pointer;
}
.font-strong[data-v-49628ad1] {
  font-weight: 700;
  font-size: 14px;
}
