.navbar[data-v-e85ea09d] {
  height: 60px;
  background-color: #343a40;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.navbar.mobile[data-v-e85ea09d] {
  padding-left: 2px;
}
.header-right[data-v-e85ea09d] {
  align-items: center;
  justify-content: center;
}
.header-right img[data-v-e85ea09d] {
  margin: 0 10px 0 20px;
}
.header-right a[data-v-e85ea09d],
p[data-v-e85ea09d],
span[data-v-e85ea09d] {
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 16px;
}
.header-right.mobile a[data-v-e85ea09d],
p[data-v-e85ea09d],
span[data-v-e85ea09d] {
  font-weight: 500;
  font-size: 10px;
  text-align: right;
}
.header-right.mobile img[data-v-e85ea09d] {
  margin-left: auto;
}