a[data-v-d867bbea] {
  color: #da0f2e;
  text-decoration: none;
  background-color: transparent;
}
a[data-v-d867bbea]:hover {
  cursor: pointer;
}
.table-container[data-v-d867bbea] {
  width: calc(100% - 60px);
}
