.margin-right[data-v-4dce97b3] {
    margin-right: 6px;
}
.selected[data-v-4dce97b3] {
    background-color: #eeeef0;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
}
.pointer[data-v-4dce97b3] {
    cursor: pointer;
}
.scroll-box__wrapper[data-v-4dce97b3] {
    max-width: 100%;
    overflow-x: auto;
    flex-direction: row;
}
.scroll-box__container[data-v-4dce97b3] {
    height: 100%;
    width: 200%;
    display: inline-flex;
}
.scroll-box__item[data-v-4dce97b3] {
    width: 100%; 
    height:100%;
    padding: 1rem .5rem;
}
.margin-left[data-v-4dce97b3] {
    margin-left: -100%;
    transition: margin 300ms;
}
