.wrap[data-v-624d32f5] {
  height: 63px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 1px 0px rgba(215, 215, 215, 0.35);
  justify-content: space-between;
  position: relative;
}
.wrap.mobile[data-v-624d32f5] {
  padding: 0 12px;
  height: 42px;
  background-color: #f6f6f6;
}
h1[data-v-624d32f5] {
  font-weight: 700;
  font-size: 30px;
}
h1.mobile[data-v-624d32f5] {
  margin-top: 5px;
  font-size: 18px;
}
.buttons-group[data-v-624d32f5] {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.btn-small[data-v-624d32f5] {
  width: 40px;
  height: 40px;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
  background-color: #da0f2e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}
.info[data-v-624d32f5] {
  width: 40px;
  height: 40px;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}
.info.mobile[data-v-624d32f5] {
  width: 32px;
  height: 32px;
}
.btn-small.mobile[data-v-624d32f5] {
  width: 32px;
  height: 32px;
}
.btn-large[data-v-624d32f5] {
  width: 140px;
  height: 40px;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
  background-color: #da0f2e;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  font-weight: 900;
  color: white;
  text-align: center;
}
.btn-info[data-v-624d32f5] {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-color: #f2f2f2;
  margin-left: 16px;
  font-family: 'Arial', sans-serif;
  font-weight: 900;
  color: #8c8c8c;
  text-align: center;
  font-size: 24px;
}
.btn-info[data-v-624d32f5]:hover {
  background-color: #da0f2e;
  color: #fff;
}
.tool-tip[data-v-624d32f5] {
  width: 300px;
  height: 81px;
  background-color: #494c4f;
  position: absolute;
  right: 40px;
  top: 30px;
  border: 1px solid #d7d7d7;
  font-size: 13px;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
}
