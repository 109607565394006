.wrap[data-v-88b1fc92] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 10px 60px;
    min-height: calc(100vh - 170px);
}
.btn[data-v-88b1fc92] {
    background-color: "#da0f2e";
    border-radius: 5px;
    font-weight: 600;
    height: 40px;
    padding: 2px 2px;
    width: 100px;
}
.btn[data-v-88b1fc92]:hover {
    background-color: #ffffff;
    cursor: pointer;
    color: #da0f2edd;
}
.button-secondary[data-v-88b1fc92] {
    font-weight: 600;
    border: 2px solid #da0f2e;
    background-color: #ffffff;
    color: #da0f2e;
}
.button-secondary[data-v-88b1fc92]:hover {
    background-color: #da0f2edd;
    cursor: pointer;
    color: white;
}
.window-container[data-v-88b1fc92] {
    width: calc(100% - 60px);
    min-height: 360px;
    border: 2px solid #da0f2e;
    background-color: #ffffff;
    margin-left: 30px;
    padding: 0 20px 40px 20px;
}
.window-header[data-v-88b1fc92] {
    height: 40px;
    width: 25%;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #da0f2e;
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    margin-left: 30px;
    margin-top: 30px;
}
.spinner[data-v-88b1fc92] {
    margin-right: 8px;
    margin-top: 10px;
}
.dropdown-spinner-container[data-v-88b1fc92] {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dropdown-spinner[data-v-88b1fc92] {
    margin-left: 10px;
}
.required[data-v-88b1fc92]::after {
    content: ' *';
    color: #da0f2e;
}
.error[data-v-88b1fc92] {
    font-family: 'Arial', sans-serif;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #da0f2e;
}