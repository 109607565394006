.wrap[data-v-8db9e015] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 10px 60px;
    min-height: calc(100vh - 170px);
}
.title[data-v-8db9e015] {
    font-weight: 700;
    color: #333333;
    font-size: 20px; 
    margin: 25px 0;
}