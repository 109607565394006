.button[data-v-3072094e] {
    height: 40px;
    width: 140px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    background-color: #da0f2e;
    font-family: 'Arial', sans-serif;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    transition: 0.1s;
    -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
    user-select: none; /* Standard */
}
.button[data-v-3072094e]:hover {
  background-color: #da0f2edd;
  cursor: pointer;
}
.button[data-v-3072094e]:active {
    box-shadow: inset 0px 0px 5px #666;
    outline: none;
}
