.menu-item[data-v-6f2efb63] {
  background-color: white;
  width: 310px;
  height: 60px;
  padding: 2px 2px 2px 10px;
  border-radius: 5px;
  border: 1px solid #797979;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #797979;
  flex-direction: row;
  display: flex;
  align-items: center;
  position: relative;
  margin: 20px 20px 20px 0;
  cursor: pointer;
  transition: 0.1s;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.menu-item[data-v-6f2efb63]:hover {
  color: #444;
  border: 1px solid #333;
  background-color: #fafafa;
  margin-top: 19px;
  text-shadow: rgba(255, 255, 255, 0.9) 0px 1px 1px;
}
.menu-item[data-v-6f2efb63]:active {
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}
.menu-text[data-v-6f2efb63] {
  font-size: 20px;
  margin-left: 10px;
}
