.wrap[data-v-eebdda2f] {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1000px;
  align-self: center;
  min-height: calc(100vh - 170px);
  align-items: center;
}
.header[data-v-eebdda2f] {
  font-size: 24px;
  font-weight: 700;
  color: #53565a;
  margin: 24px 0;
  align-self: center;
}
.row[data-v-eebdda2f] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -10px; /* Add negative margin to offset spacing */
}
.row > div[data-v-eebdda2f] {
  flex-basis: calc(33.33% - 20px); /* Adjust the width of each menu item */
  margin: 10px; /* Add margin to create spacing */
}
