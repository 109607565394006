.wrap[data-v-b25cca2c] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 30px;
    /* max-width: 1100px; */
    align-self: center;
}
.filters-row[data-v-b25cca2c] {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.filter-row[data-v-b25cca2c] {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding-left: 15px;
    width: 100%;
    margin-top: 15px;
}
.buttons-row[data-v-b25cca2c] {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.button-secondary[data-v-b25cca2c] {
    font-weight: 600;
    border: 2px solid #da0f2e;
    background-color: #ffffff;
    color: #da0f2e;
}
.top-row[data-v-b25cca2c] {
    width: 100%;
    flex-direction: row;
    align-items: center;
    display: flex;
    padding-left: 15px;
    justify-content: flex-start;
}
.top-col[data-v-b25cca2c] {
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 30%;
}
.dropdown-spinner-container[data-v-b25cca2c] {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dropdown-spinner[data-v-b25cca2c] {
    margin-left: 10px;
}
.overlay-box[data-v-b25cca2c] {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    z-index: 100000;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}
.spinner[data-v-b25cca2c] {
    position: absolute;
    top: 50%;
    left: 50%;
}
.required[data-v-b25cca2c]::after {
    content: ' *';
    color: #da0f2e;
}