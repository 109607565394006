.wrap[data-v-f0c7074d] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 50px;
}
.mobile-wrap[data-v-f0c7074d] {
  padding: 0;
  margin-top: 0;
}
.inner-wrap[data-v-f0c7074d] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  border: 2px solid black;
  padding: 20px;
}
.title-container[data-v-f0c7074d] {
  width: 100%;
  height: 4rem;
  background-color: #53565a;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobile-title-container[data-v-f0c7074d] {
  height: 1.5rem;
  border-radius: 0;
}
.title[data-v-f0c7074d] {
  font-size: 1.4rem;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: white;
  text-align: center;
  margin: 32px 0;
}
.mobile-title[data-v-f0c7074d] {
  font-size: 1rem;
  padding-top: 2px;
}
.client-data-row[data-v-f0c7074d] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-name[data-v-f0c7074d] {
  color: #da102d;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  text-align: left;
  line-height: normal;
  font-size: 24px;
  flex: 1 1;
}
.dqf-code[data-v-f0c7074d] {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  text-align: left;
  line-height: normal;
  width: 200px;
}
.info-row[data-v-f0c7074d] {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
}
.label[data-v-f0c7074d] {
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #333333;
  text-align: left;
  line-height: normal;
  align-items: center;
  margin-right: 10px;
}
.data[data-v-f0c7074d] {
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #333333;
  text-align: left;
  line-height: normal;
  align-items: center;
  opacity: 0.5;
}
.info-container[data-v-f0c7074d] {
  display: flex;
  align-items: center;
  min-width: 250px;
  justify-content: flex-start;
}
.document[data-v-f0c7074d] {
  width: 100%;
  height: 460px;
  background-color: gray;
  margin: auto;
  margin-top: 20px;
}
.comments-container[data-v-f0c7074d] {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.comments-input[data-v-f0c7074d] {
  width: 100%;
  height: 170px;
  padding: 20px;
  margin-top: 20px;
  resize: none;
}
.comments-input.mobile[data-v-f0c7074d] {
  height: 110px;
  padding: 5px;
}
.buttons-container[data-v-f0c7074d] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
}
.button[data-v-f0c7074d] {
  font-weight: 700;
  font-size: 16px;
  width: 240px;
}
.mobile-info-container[data-v-f0c7074d] {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-start;
}
.mobile-label[data-v-f0c7074d] {
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #333333;
  text-align: left;
  line-height: normal;
  align-items: center;
  margin-right: 5px;
}
.mobile-data[data-v-f0c7074d] {
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #333333;
  text-align: left;
  line-height: normal;
  align-items: center;
  opacity: 0.5;
}
.title-expiration[data-v-f0c7074d] {
  width: 72%;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
.centered[data-v-f0c7074d] {
  align-items: center;
  justify-content: center;
}
.get-link-button[data-v-f0c7074d] {
  margin-top: 35px;
  padding: 5px 25px 5px 25px;
  font-weight: 700;
}
.enter-code-container[data-v-f0c7074d] {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
}
.required-field[data-v-f0c7074d] {
  color: #da102d;
  margin-left: 5px;
}
.input[data-v-f0c7074d] {
  width: 245px;
  height: 30px;
  padding: 5px 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #797979;
  font-family: 'Arial', sans-serif;
}
.verify-button[data-v-f0c7074d] {
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  width: 245px;
}
.top-wrapper[data-v-f0c7074d] {
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 33px;
}
.send-code-text[data-v-f0c7074d] {
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 18px;
}
.send-code-button[data-v-f0c7074d] {
  font-weight: 700;
  font-size: 16px;
  width: 245px;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
}
.row[data-v-f0c7074d] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.scroll-list[data-v-f0c7074d] {
  width: 100%;
}
.gray-text[data-v-f0c7074d] {
  color: gray;
}
.summary-input[data-v-f0c7074d] {
  width: 100%;
  resize: none;
  font-size: 12px;
}
.empty-div[data-v-f0c7074d] {
  width: 100%;
  height: 20px;
}
