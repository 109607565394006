.wrap[data-v-6724d419] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  /* max-width: 1100px; */
  align-self: center;
}
.buttons-container[data-v-6724d419] {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 30px;
}
.button-text[data-v-6724d419] {
  font-weight: 600;
}
.button-secondary[data-v-6724d419] {
  font-weight: 600;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  color: #da0f2e;
}
