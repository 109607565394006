.test-style[data-v-b07d618f] {
  background-color: #990c23;
  border-color: #8d0b20;
  color: #fff;
}
.wrap[data-v-b07d618f] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1300px;
  align-self: center;
  align-items: center;
  margin-top: 30px;
}
.content-container[data-v-b07d618f] {
  display: flex;
  flex-direction: column;
}
.title[data-v-b07d618f] {
  font-size: 1.25rem;
  font-weight: 400;
  font-family: 'Arial', sans-serif;
}
.inputs[data-v-b07d618f] {
  width: 400px;
}
.button-text[data-v-b07d618f] {
  font-weight: 600;
}
