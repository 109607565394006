.wrap[data-v-d71a4480] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  /* max-width: 1100px; */
  align-self: center;
}
.filters-row[data-v-d71a4480] {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.filter-row[data-v-d71a4480] {
  flex-direction: row;
  align-items: center;
  display: flex;
  padding-left: 15px;
  width: 100%;
  margin-top: 15px;
}
.buttons-row[data-v-d71a4480] {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.button-secondary[data-v-d71a4480] {
  font-weight: 600;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  color: #da0f2e;
}
.button-ternary[data-v-d71a4480] {
  font-weight: 600;
  background-color: #6c757d;
  border: 2px solid #d7d7d7;
  color: white;
  text-wrap: nowrap;
}
.top-row[data-v-d71a4480] {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
}
.top-col[data-v-d71a4480] {
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 30%;
}
.dropdown-spinner-container[data-v-d71a4480] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown-spinner[data-v-d71a4480] {
  margin-left: 10px;
}
.error[data-v-d71a4480] {
  font-family: 'Arial', sans-serif;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #da0f2e;
}
.required[data-v-d71a4480]::after {
  content: ' *';
  color: #da0f2e;
}