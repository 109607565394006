.hiddenText[data-v-d3a42baa] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 20%;
}
.table-cells[data-v-d3a42baa] {
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
}
