.modal-background[data-v-23dcd106] {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal[data-v-23dcd106] {
  position: relative;
  background-color: white;
  min-width: 50%;
  max-width: 500px;
  height: auto;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.modal-header[data-v-23dcd106] {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bold-column[data-v-23dcd106] {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
}
.btn[data-v-23dcd106] {
  background-color: #da0f2e;
  border-color: #da0f2e;
  min-width: 25%;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 700;
}
.border[data-v-23dcd106] {
  padding: 15px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  border-color: #da0f2e;
}
.btn-wrapper[data-v-23dcd106] {
  flex-direction: row;
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.btn-center[data-v-23dcd106] {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.filter-row[data-v-23dcd106] {
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
}
.form-group[data-v-23dcd106] {
  flex: 1 1;
}
.gray-text[data-v-23dcd106] {
  color: gray;
}
.invalid-input[data-v-23dcd106] {
  color: #c8102e;
  font-size: 12px;
}
.dropdown[data-v-23dcd106] {
  background-color: white;
  min-width: 100%;
  border-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: black;
  height: 40;
}
.scroll-list[data-v-23dcd106] {
  max-height: 100px;
  overflow: scroll;
}
span[data-v-23dcd106] {
  color: #da0f2e;
}
.small-margin-top[data-v-23dcd106] {
  margin-top: 10px;
}
.top-col[data-v-23dcd106] {
  width: 50%;
}
.error[data-v-23dcd106] {
  font-family: 'Arial', sans-serif;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #da0f2e;
}
