.window-container[data-v-3a2b6e4d] {
  width: calc(100% - 60px);
  min-height: 360px;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  margin-left: 30px;
  padding: 0 20px 40px 20px;
}
.window-header[data-v-3a2b6e4d] {
  height: 40px;
  width: 25%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #da0f2e;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 30px;
  margin-top: 30px;
}