.wrap[data-v-d80106c2] {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
  min-height: calc(100vh - 170px);
}
.inner-wrap[data-v-d80106c2] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 708px;
}
.title[data-v-d80106c2] {
  font-size: 22px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #53565a;
  text-align: center;
  margin: 32px 0;
}
.mobile-row[data-v-d80106c2] {
  display: flex;
  justify-content: center;
}
