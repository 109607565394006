.wrap[data-v-c7798210] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1100px;
  align-self: center;
}
.header[data-v-c7798210] {
  font-size: 24px;
  font-weight: 700;
  color: #da0f2e;
  margin: 24px 0 0 30px;
  align-self: left;
}
.row[data-v-c7798210] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sub-header[data-v-c7798210] {
  font-size: 24px;
  font-weight: 700;
  color: #53565a;
  margin: 24px 0 0 30px;
}
.top-row[data-v-c7798210] {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding-left: 16px;
}
.top-col[data-v-c7798210] {
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 30%;
}
.title[data-v-c7798210] {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.top-value[data-v-c7798210] {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  height: 30px;
}
.label[data-v-c7798210] {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  height: 30px;
}
.gray-divider-strong[data-v-c7798210] {
  border: 2px solid #797979;
  background-color: #797979;
  margin-top: 16px;
}
.below-container[data-v-c7798210] {
  width: 385px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gray-divider-light[data-v-c7798210] {
  border: 1px solid #797979;
  background-color: #797979;
  margin-top: 16px;
  opacity: 0.5;
}
.another-receipt[data-v-c7798210] {
  cursor: pointer;
  color: #da0f2e;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
  margin-top: 15px;
}
.button-text[data-v-c7798210] {
  font-weight: 600;
}
.button-secondary[data-v-c7798210] {
  font-weight: 600;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  color: #da0f2e;
}
.gray-wrapper[data-v-c7798210] {
  border-radius: 10px;
  border: 1px solid #797979;
}
.error[data-v-c7798210] {
  font-family: 'Arial', sans-serif;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #da0f2e;
}
.required[data-v-c7798210]::after {
  content: ' *';
  color: #da0f2e;
}
.delete-row[data-v-c7798210] {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  color: #da0f2e;
  cursor: pointer;
}
.receipt-wrapper[data-v-c7798210] {
  padding: 20px 0 0 0;
  position: relative;
}
.warning-container[data-v-c7798210] {
  position: absolute;
  left: 16px;
  top: 50%;
}
.overlay-box[data-v-c7798210] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  z-index: 100000;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.spinner[data-v-c7798210] {
  position: absolute;
  top: 50%;
  left: 50%;
}
.dropdown-spinner-container[data-v-c7798210] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown-spinner[data-v-c7798210] {
  margin-left: 10px;
}
.centered-text[data-v-c7798210] {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
}
.edit-form-spinner[data-v-c7798210] {
  height: 212px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 40px;
}
.table-container[data-v-c7798210] {
  margin: 30px;
  margin-top: 0px;
  border: 2px solid #da0f2e;
  border-top: none;
  background-color: #ffffff;
}