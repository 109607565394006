.wrap[data-v-0e2c7ed4] {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  max-width: 750px;
  align-self: center;
  min-height: calc(100vh - 170px);
  align-items: center;
}
.header[data-v-0e2c7ed4] {
  font-size: 24px;
  font-weight: 700;
  color: #53565a;
  margin: 24px 0;
  align-self: center;
}
.row[data-v-0e2c7ed4] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
