.footer[data-v-fc6bc9dd] {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding: 0 1.5em;
  border: 1px solid #797979;
  position: absolute;
  bottom: 0;
  background-color: white;
}
.footer-item[data-v-fc6bc9dd] {
  align-self: center;
}
.footer.mobile[data-v-fc6bc9dd] {
  padding: 0 .5em;
}
.footer-item.mobile[data-v-fc6bc9dd] {
  font-size: 11px;
}
