.tabs-general-container[data-v-75463f81] {
  min-width: 800px;
  margin: auto;
  min-height: 500px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}
.tabs-switcher[data-v-75463f81] {
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 0 0;
}
.active-tab[data-v-75463f81] {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background-color: #990c23;
  border: 1px solid #8d0b20;
  color: #fff;
}
.inactive-tab[data-v-75463f81] {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background-color: #53565a;
  color: #fff;
}
.current-content-container[data-v-75463f81] {
  width: 100%;
  height: 100%;
  border: 1px solid #8d0b20;
  padding: 20px 20px 20px 20px;
}
