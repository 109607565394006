.test-style[data-v-de9c9abe] {
  background-color: #990c23;
  border-color: #8d0b20;
  color: #fff;
}
.wrap[data-v-de9c9abe] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 30px;
  max-width: 1300px;
  align-self: center;
  align-items: center;
  min-height: calc(100vh - 170px);
}
.content-container[data-v-de9c9abe] {
  display: flex;
  flex-direction: column;
}
.title[data-v-de9c9abe] {
  font-size: 1.25rem;
  font-weight: 400;
  font-family: 'Arial', sans-serif;
}
.inputs[data-v-de9c9abe] {
  width: 400px;
}
.button-text[data-v-de9c9abe] {
  font-weight: 600;
}
.error[data-v-de9c9abe] {
  color: red;
  margin-bottom: 15px;
}
