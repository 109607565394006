.wrap[data-v-69d5c853] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 10px 60px;
    min-height: calc(100vh - 170px);
}
.header[data-v-69d5c853] {
    font-size: 24px;
    font-size: 20px;
    font-weight: 700;
}
.button[data-v-69d5c853] {
    min-width: 390px;
    height: 42px;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid #797979;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #797979;
    text-align: center;
    line-height: 40px;
    margin: 12px 0;
    margin-right: 40px;
    transition: 0.1s;
    -webkit-user-select: none;
    /* Safari */
    /* Firefox */
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}
.button[data-v-69d5c853]:hover {
    background-color: #da0f2edd;
    cursor: pointer;
    color: white;
}
.button[data-v-69d5c853]:active {
    color: white;
    box-shadow: inset 0px 0px 5px #666;
    outline: none;
}
.row[data-v-69d5c853] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0px;
}