.navigation-header[data-v-f5f3f2dd] {
  height: 60px;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  margin-top: 30px;
}
.left-title[data-v-f5f3f2dd] {
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  text-decoration: underline;
  color: #000000;
  text-align: left;
  line-height: normal;
  margin-right: 10px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.right-title[data-v-f5f3f2dd] {
  margin-left: 10px;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #da0f2e;
  text-align: left;
  line-height: normal;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
