.wrap[data-v-d37d7fa7] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 10px 60px;
  min-height: calc(100vh - 170px);
}
.header[data-v-d37d7fa7] {
  font-size: 24px;
  margin-bottom: 10px;
}
.button[data-v-d37d7fa7] {
  min-width: 390px;
  height: 42px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid #797979;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #797979;
  text-align: center;
  line-height: 40px;
  margin: 12px 0;
  margin-right: 40px;
  transition: 0.1s;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}
.button[data-v-d37d7fa7]:hover {
  background-color: #da0f2edd;
  cursor: pointer;
  color: white;
}
.button[data-v-d37d7fa7]:active {
  color: white;
  box-shadow: inset 0px 0px 5px #666;
  outline: none;
}
.section[data-v-d37d7fa7] {
  margin: 1em 0;
  max-width: 1300px;
}
.row[data-v-d37d7fa7] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.plus[data-v-d37d7fa7] {
  position: relative;
  top: -60px;
  right: 60px;
  font-weight: 800;
  margin: 15px;
  margin-right: 0px;
}
.plusContainer[data-v-d37d7fa7] {
  width: '100%';
  height: inherit;
  display: flex;
  justify-content: flex-end;
}
.secondary-button[data-v-d37d7fa7] {
  color: #da0f2e;
  background-color: white;
  border-color: #da0f2e;
  flex: 1 1;
  margin: 0.25rem 12px;
  max-width: 160px;
}
.secondary-button[data-v-d37d7fa7]:hover {
  color: #da0f2e;
  background-color: rgb(255, 184, 195);
  border-color: #da0f2e;
}
.secondary-button[data-v-d37d7fa7]:disabled:hover {
  color: white;
  background-color: #6c757d;
  border-color: #6c757d;
}
.primary-button[data-v-d37d7fa7] {
  background-color: #da0f2e;
  border-color: #da0f2e;
  flex: 1 1;
  margin: 0.25rem 12px;
  max-width: 160px;
}
