.wrap[data-v-c2fad22a] {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 100%;
}
.container[data-v-c2fad22a] {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin-top: 5%;
    max-width: 100%;
}
.container-border[data-v-c2fad22a] {
    display: flex;
    flex-direction: row;
    border: 5px solid #666;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.signInCooporateID[data-v-c2fad22a] {
    height: auto;
    min-width: 350px;
    margin-right: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 0px;
}
.signInEmailPassword[data-v-c2fad22a] {
    height: auto;
    min-width: 350px;
    margin-left: 5px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 10px;
    padding-top: 0px;
}
.btn[data-v-c2fad22a] {
    margin: 5px 0 0 0;
    border-width: 1px;
    border-color: #c8102e;
    color: #ffff;
    font-weight: bold;
}
.link[data-v-c2fad22a] {
    color: #5bc0de;
}
.divider[data-v-c2fad22a] {
    position: relative;
    left: 50%;
    margin-left: -3px;
    top: 0;
    border-left: 2px solid lightgray;
    height: 135px;
    opacity: 50%;
}
.divider-text[data-v-c2fad22a] {
    margin-top: 6px;
    color: lightgray;
    text-align: center;
}
.required[data-v-c2fad22a]::after {
    content: ' *';
    color: #da0f2e;
}
.password-border[data-v-c2fad22a] {
    border: 5px solid #666;
    padding: 16px;
}
.forgot-password-error[data-v-c2fad22a] {
    font-size: 15px;
}