.wrap[data-v-211e5a69] {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1100px;
  align-items: center;
  min-height: calc(100vh - 170px);
}
.inner-wrap[data-v-211e5a69] {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  width: 708px;
}
.title[data-v-211e5a69] {
  font-size: 22px;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #53565a;
  text-align: center;
  margin: 32px 0;
}
