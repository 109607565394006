.button-secondary[data-v-f5e6cb90] {
    font-weight: 600;
    border: 2px solid #da0f2e;
    background-color: #ffffff;
    color: #da0f2e;
}
.button-ternary[data-v-f5e6cb90] {
    font-weight: 600;
    background-color: #6c757d;
    border: 2px solid #d7d7d7;
    color: white;
    text-wrap: nowrap;
}
.container[data-v-f5e6cb90] {
    max-width: 100%;
}
.header[data-v-f5e6cb90] {
    color: #da0f2edd;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}
.row[data-v-f5e6cb90] {
    display: flex;
    align-items: center;
}
.text[data-v-f5e6cb90] {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}
.wrap[data-v-f5e6cb90] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-height: calc(100vh - 170px);
}
.dropdown-spinner-container[data-v-f5e6cb90] {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dropdown-spinner[data-v-f5e6cb90] {
    margin-left: 10px;
    margin-bottom: 8px;
}