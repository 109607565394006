.scroll-list[data-v-0b109b07] {
  width: 100%;
}
.gray-text[data-v-0b109b07] {
  color: gray;
}
.summary-input[data-v-0b109b07] {
  width: 100%;
  resize: none;
}
.empty-div[data-v-0b109b07] {
  width: 100%;
  height: 20px;
}
