.wrap[data-v-6ca91339] {
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding: 33px 30px;
  min-height: calc(100vh - 170px);
}
.inner[data-v-6ca91339] {
  width: 70%;
  display: flex;
  flex-flow: column;
}
.content[data-v-6ca91339] {
  border: 2px solid #da0f2e;
  height: 100%;
  padding: 30px;
}
.bar[data-v-6ca91339] {
  height: 40px;
  padding: 2px;
  background-color: #d9001b;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.label[data-v-6ca91339] {
  color: #33333388;
  font-size: 18px;
  margin: 0;
}
.box[data-v-6ca91339] {
  border: 1px solid #ccc;
  padding: 25px;
  margin: 20px 10px;
}
.dropdown[data-v-6ca91339] {
  background-color: 'white';
  min-width: 49%;
  border-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 200;
  color: black;
}
.or-add-new[data-v-6ca91339] {
  font-weight: 700;
  color: #da0f2e;
  text-align: center;
  width: 100%;
  margin: 30px 0;
}
.error[data-v-6ca91339] {
  color: red;
  font-size: smaller;
  margin-top: 5px;
}
