.wrap[data-v-6c2f3d0a] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1100px;
  align-self: center;
  min-height: calc(100vh - 170px);
}
.header[data-v-6c2f3d0a] {
  font-size: 24px;
  font-weight: 700;
  color: #53565a;
  margin: 24px 0;
}
