.enter-code-container[data-v-c1ba6c11] {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
}
.required-field[data-v-c1ba6c11] {
  color: #da102d;
  margin-left: 5px;
}
.input[data-v-c1ba6c11] {
  width: 245px;
  height: 30px;
  padding: 5px 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #797979;
  font-family: 'Arial', sans-serif;
}
.verify-button[data-v-c1ba6c11] {
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  width: 245px;
}
.top-wrapper[data-v-c1ba6c11] {
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 33px;
}
.send-code-text[data-v-c1ba6c11] {
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 18px;
}
.button-secondary[data-v-c1ba6c11] {
  font-weight: 700;
  border: 2px solid #da0f2e;
  background-color: #ffffff;
  color: #da0f2e;
}
.wrap[data-v-c1ba6c11] {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 30px;
  max-width: 1100px;
  align-self: center;
  align-items: center;
}
.title-expiration[data-v-c1ba6c11] {
  width: 72%;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  padding-top: 20px;
}
.centered[data-v-c1ba6c11] {
  align-items: center;
  justify-content: center;
}
.error-message[data-v-c1ba6c11] {
  color: #da102d;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}