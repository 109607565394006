.wrap[data-v-614c9cab] {
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding: 30px 60px;
    min-height: calc(100vh - 170px);
}
.title[data-v-614c9cab] {
    font-weight: 700;
    color: #333333;
    font-size: 20px; 
    margin: 25px 0;
}
.thick-label[data-v-614c9cab] {
    font-weight: 700;
    color: #333333;
    font-size: 20px;
}
.selectorList[data-v-614c9cab] {
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.bar[data-v-614c9cab] {
    height: 40px; 
    padding: 2px;
    background-color: #d9001b;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.label[data-v-614c9cab] {
    color: #333333;
    font-size: 20px;
    margin: 0;
}
.value[data-v-614c9cab] {
    color: #33333388;
    font-size: 16px;
    display: flex;
    padding: 5px 0;
}
.content[data-v-614c9cab] {
    border: 2px solid #da102d;
    padding: 20px;
}
.inner[data-v-614c9cab] {
    width: 1140px;
    display: flex;
    flex-flow: column;
    height: 100%;
}
.inner-inner[data-v-614c9cab] {
    justify-content: space-between;
    display: flex;
    margin: 0;
}
.row-margin[data-v-614c9cab] {
    margin-top: 15px;
}
.dropdown[data-v-614c9cab] {
    border: 1px solid #333;
    border-radius: 5px;
}
.rectangle[data-v-614c9cab] {
    width: 100%;
    height: 304px;
    background-color: #ddd;
}
.headers[data-v-614c9cab] {
    margin-top: 20px;
    font-weight: 700;
}
.plus[data-v-614c9cab] {
    font-weight: 800;
    margin:15px;
}
.button[data-v-614c9cab] {
    height: 40px;
    margin: 0 15px;
}