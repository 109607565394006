.wrap[data-v-1a762d12] {
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding: 33px 30px;
    min-height: calc(100vh - 170px);
}
.inner[data-v-1a762d12] {
    width: 1140px;
    display: flex;
    flex-flow: column;
}
.content[data-v-1a762d12] {
    border: 2px solid #da0f2e;
    padding: 30px;
}
.bar[data-v-1a762d12] {
    height: 40px; 
    padding: 2px;
    background-color: #d9001b;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.label[data-v-1a762d12] {
    color: #33333388;
    font-size: 18px;
    margin: 0;
}
.box[data-v-1a762d12] {
    border: 1px solid #ccc;
    padding: 15px;
    margin: 10px;
}
.dropdown[data-v-1a762d12] {
        background-color: 'white';
        min-width: 49%;
        border-color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight:200;
        color: black;
}
.or-add-new[data-v-1a762d12] {
    font-weight: 700;
    color: #da0f2e;
    text-align: center;
    width: 100%;
    margin: 30px 0;
}
.error[data-v-1a762d12] {
    color: red;
    font-size: smaller;
    margin-top: 5px;
}