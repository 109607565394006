.wrap[data-v-f131280d] {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 33px 30px;
    max-width: 1200px;
    align-self: center;
    min-height: calc(100vh - 170px);
}
.inner[data-v-f131280d] {
    width: 1140px;
    display: flex;
  flex-flow: column;
  height: 100%;
}
.content[data-v-f131280d] {
    border: 2px solid #da0f2e;
    height: 100%;
    padding: 30px;
}
.bar[data-v-f131280d] {
    height: 40px; 
    padding: 2px;
    background-color: #d9001b;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.label[data-v-f131280d] {
    color: #333333;
    font-size: 20px;
    margin: 0;
}
.value[data-v-f131280d] {
    color: #333333;
    font-size: 20px;
    opacity: .5;
}
.btn-txt[data-v-f131280d] {
    font-weight: 700;
    float: right;
}
.inverse[data-v-f131280d] {
    background-color: white;
    color: #da0f2e;
}
.rectangle[data-v-f131280d] {
    border: 1px solid #d7d7d7;
    margin: 30px 0 55px 0; 
    padding: 10px 20px;
}
.modal-wrap[data-v-f131280d] {
    flex: 1 1;
    position: absolute;
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    background-color: #ffffffaa;
}
.hz-m[data-v-f131280d] {
    margin: 0 12px;
}
.spinner-wrap[data-v-f131280d] {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}