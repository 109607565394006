.top-row[data-v-ff7f32a5] {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding-left: 16px;
}
.top-col[data-v-ff7f32a5] {
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 30%;
}
.error[data-v-ff7f32a5] {
  font-family: 'Arial', sans-serif;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #da0f2e;
}
.required[data-v-ff7f32a5]::after {
  content: ' *';
  color: #da0f2e;
}
.dropdown-spinner-container[data-v-ff7f32a5] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown-spinner[data-v-ff7f32a5] {
  margin-left: 10px;
}
.centered-text[data-v-ff7f32a5] {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
}
.below-container[data-v-ff7f32a5] {
  width: 385px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
