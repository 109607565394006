.header[data-v-ba13b33d] {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  max-width: 100%;
}
.title[data-v-ba13b33d] {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-left: 36px;
  margin-bottom: 0px;
  margin-top: 16px;
}
.table-body[data-v-ba13b33d] {
  border-radius: 10px;
  border: 1px solid #797979;
  margin: 8px;
  margin-bottom: 30px;
}
.table-row[data-v-ba13b33d] {
  margin-top: 0px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}