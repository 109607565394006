.row[data-v-5d5cccce] {
    padding: 5px 0;
}
.top-pad[data-v-5d5cccce] {
    padding-top: 10px;
}
.top-margin[data-v-5d5cccce] {
    margin-top: 5px;
}
.center[data-v-5d5cccce] {
   padding-left: 15px;
}
.highlight[data-v-5d5cccce] {
    background-color: #ff000011;
    border: 1px solid #ff000044;
}